import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQuery,
    tagTypes:['user','followers','following','interest','bookmarkArticles'],
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => '/user/profile',
            providesTags : ['user'],
        }),
        getAuthorById:builder.query({
            query:(id)=>`/user/profile/${id}`
        }),
        getAuthorByUsername:builder.query({
            query:(username)=>`/user/${username}`
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: '/user/profile',
                method: 'PUT',
                body: data
            }),
            invalidatesTags:['user']
        }),
        updatePassword: builder.mutation({
            query: (data) => ({
                url: '/user/password',
                method: 'PUT',
                body: data
            }),
            invalidatesTags:['user']
        }),
        // following queries
        followUser: builder.mutation({
            query: (userId) => ({
              url: `/users/${userId}/follow`,
              method: 'POST',
            }),
            invalidatesTags:['followers','following']
        }),
        getFollowers: builder.query({
            query: (userId) => `/users/${userId}/followers`,
            providesTags:['followers']
        }),
        getFollowings: builder.query({
            query: (userId) => `/users/${userId}/following`,
            providesTags:['following']
        }),
        bookmarkArticle:builder.mutation({
            query:(articleId)=>({
                url:`/users/bookmark/${articleId}`,
                method: 'POST',
            }),
            invalidatesTags:['user','bookmarkArticles']
        }),
        getBookmarkArticle:builder.query({
            query:()=>'/users/bookmark-articles',
            providesTags:['bookmarkArticles']
        }),
        getInterest:builder.query({
            query:()=>'/users/interests',
            providesTags:['interest']
        })
    }),
});

export const { 
    useGetUserQuery,
    useUpdateUserMutation ,
    useOnboardUpdateUserMutation,
    useUpdatePasswordMutation,
    useGetBookmarkArticleQuery,
    useGetAuthorByIdQuery,
    useFollowUserMutation,
    useGetFollowersQuery,
    useGetFollowingsQuery,
    useBookmarkArticleMutation,
    useToggleInterestMutation,
    useGetInterestQuery,
    useGetAuthorByUsernameQuery
} = userApi;
