'use client'
import { ArrowUp, ChevronLeft, Delete, Edit, Ellipsis, Pencil, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAddCommentMutation, useGetCommentsQuery,useEditCommentMutation,useDeleteCommentMutation } from '@/RTK/services/articleApi.js';
import { toast } from 'react-hot-toast';
import { avatar } from '../../../../../public/images';
import Loader from '../../../../utils/Loader';
import { useSelector } from 'react-redux';

// Validation schema
const commentSchema = yup.object().shape({
  comment: yup.string().required('التعليق مطلوب').min(1, 'التعليق مطلوب'),
});

const CommentModal = ({ isOpen, onClose, articleId }) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: "90%",
            maxWidth: '400px',
            height: "500px",  // Ensure height is set for modal
            transform: 'translate(-50%, -50%)',
            background: "white",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            padding: 0,  // Remove default padding
        },
        // overlay: {
        //     backgroundColor: "rgba(0, 0, 0, 0.1)",  // Added opacity to overlay
        // },
    };
    const currentUserId = useSelector((state)=>state.auth?.user?._id)
    console.log(currentUserId)
    const [selected, setSelected] = useState('كل التعليقات');
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editedCommentText, setEditedCommentText] = useState('');
    const [editComment , {isLoading:isUpdating}] = useEditCommentMutation();

    // RTK Query hook - note we're accessing data.comments
    const { data, isLoading } = useGetCommentsQuery(
        { articleId, filter: selected },
        { skip: !isOpen }
    );
    // Get comments array from the response
    const comments = data?.comments || [];

    // RTK Query hooks
    const [addComment, { isLoading: isSubmitting }] = useAddCommentMutation();
    const [deleteComment] = useDeleteCommentMutation()

    const handleEdit = async (commentId) => {
        try {
            await editComment({ articleId, commentId, comment: editedCommentText }).unwrap();
            setEditingCommentId(null); // Reset editing state
            setEditedCommentText(''); // Clear the input after editing
            toast.success('تم تعديل التعليق بنجاح');
        } catch (error) {
            toast.error(error.data?.message || 'فشل في تعديل التعليق');
        }
    };
    
    const handleDelete = async(commentId)=>{
        await deleteComment({articleId,commentId}).unwrap();
    }
    // React Hook Form
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(commentSchema),
        defaultValues: {
            comment: ''
        }
    });
    
    // Submit handler
    const onSubmit = async (data) => {
        try {
            await addComment({
                articleId,
                comment: data.comment
            }).unwrap();
            
            // Reset form after successful submission
            reset();
            toast.success('تم إضافة التعليق بنجاح');
        } catch (error) {
            toast.error(error.data?.message || 'فشل في إضافة التعليق');
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Comment Modal"
            closeTimeoutMS={500}
        >
            <div className='flex flex-col relative gap-3 h-full'>
                <div className="flex w-full justify-between items-center p-2 border-b">
                    <IoClose className="cursor-pointer" size={20} onClick={onClose} />
                    <div className='flex gap-2 items-center'>
                        <p  onClick={() => setSelected('جديد التعليقات')} 
                            className={`text-[12px] ${selected === 'جديد التعليقات' ? "text-[#8641BD]" : "text-[#8C97A7]"} font-[500] cursor-pointer`}
                        >جديد التعليقات </p>
                        <p onClick={() => setSelected('كل التعليقات')} 
                            className={`text-[12px] font-[500] ${selected === 'كل التعليقات' ? "text-[#8641BD]" : "text-[#8C97A7]"} cursor-pointer`}
                        >  كل التعليقات</p>
                        <p className='text-[16px] items-center flex font-[600]'>
                            <ChevronLeft className="cursor-pointer" width={20} height={20} />
                            التعليقات</p>
                    </div>
                </div>

                {/* Comments List */}
                <div style={{scrollbarWidth:'none'}} className="flex-1 overflow-y-auto p-3">
                    {isLoading ? (
                        <div className="flex items-center justify-center w-full h-full">
                        <Loader/>
                        </div>
                    ) : comments.length === 0 ? (
                        <div className="text-center text-gray-500">لا توجد تعليقات</div>
                    ) : (
                        comments.map((comment, i) => (
                            <div key={i} className={`flex flex-col gap-2 ${comment.person === 'author' ? 'bg-[#F4F4F4]' : 'bg-white'} rounded-md p-3 mb-2`}>
                                <span className='w-full relative items-center justify-between flex'>
                                    <span className='flex gap-2 items-center justify-center'>
                               {   (comment?.postedBy == currentUserId || comment?.person == 'author')  &&   <Ellipsis onClick={()=>setEditingCommentId(comment.id)} size={12} className='cursor-pointer'/>}
                                    <p className='text-[10px] font-[500]'>{comment?.date}</p>
                                    </span>
                                    {editingCommentId === comment.id &&  <span className='absolute -bottom-2 left-0 flex gap-2 '>
                                        <Pencil  onClick={()=>{setEditedCommentText(comment.comment)}} size={10} className='cursor-pointer hover:text-purple-900'/>
                                        <Trash2 onClick={()=>handleDelete(comment.id)} size={10} className='cursor-pointer hover:text-red-800'/>
                                        </span>}
                                    <span className='flex gap-2 items-center'>
                                        <p className='text-[12px] font-[600]'>{comment?.name}</p>
                                        <Image alt='' src={comment?.image || avatar} className='w-[20px] h-[20px] rounded-full' width={100} height={100} />
                                    </span>
                                </span>
                                {editingCommentId === comment.id && editedCommentText !== ''  ? (
                                    <div className="flex gap-2">
                                        <button
                                            onClick={() => handleEdit(comment.id)}
                                            disabled={isUpdating}
                                            className={`bg-[#8641BD] self-center cursor-pointer p-1 rounded-full ${isUpdating ? 'opacity-50' : ''}`}>
                                            {isUpdating ? (
                                                <div className="w-5 h-5 border-t-2 border-white rounded-full animate-spin" />
                                            ) : (
                                                <ArrowUp size={10} color='white' />
                                            )}
                                        </button>
                                        <input
                                            type="text"
                                            value={editedCommentText}
                                            onChange={(e) => setEditedCommentText(e.target.value)} // Update the edited comment text
                                            className='outline-none border-b border-black w-full text-right p-1'
                                        />
                                    </div>
                                ) : (
                                    <span style={{direction:'rtl'}} className="text-[12px] text-right  font-[400] text-opacity-60">{comment.comment}</span>
                                )}

                            </div>
                        ))
                    )}
                </div>

                {/* Comment Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="flex justify-between items-center p-2 border-t bg-white">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`bg-[#8641BD] self-center cursor-pointer p-1 rounded-full ${isSubmitting ? 'opacity-50' : ''}`}
                    >
                        {isSubmitting ? (
                            <div className="w-5 h-5 border-t-2 border-white rounded-full animate-spin" />
                        ) : (
                            <ArrowUp size={20} color='white' />
                        )}
                    </button>
                    <input 
                        className={`outline-none w-full text-right p-1 ${errors.comment ? 'border-red-500' : ''}`}
                        placeholder='اكتب تعليقك هنا'
                        {...register('comment')}
                    />
                </form>
                {errors.comment && (
                    <p className="text-red-500 text-xs text-right px-2">
                        {errors.comment.message}
                    </p>
                )}
            </div>
        </Modal>
    );
};

export default CommentModal;
