'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import {
  useLikeArticleMutation,
  useUnlikeArticleMutation,
} from '@/RTK/services/articleApi';
import { useBookmarkArticleMutation ,useGetUserQuery} from '@/RTK/services/userApi';
import { Bookmark, MessageCircleMore } from 'lucide-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { FiCopy } from 'react-icons/fi';
import { HiMiniBookmark } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { demoCoverImage } from '../../../../assets';
import { formatDate } from '../../../../utils/Formatdate';
import CommentModal from '../explore/CommentModal';

const ExploreArticleCard = ({ article }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  // const [bookmark, setBookmark] = useState(false);
  const [likeArticle] = useLikeArticleMutation();
  const [unlikeArticle] = useUnlikeArticleMutation();
  const [bookmarkArticle, { isSuccess }] = useBookmarkArticleMutation();
  const currentUser = useSelector((state) => state.auth.user); // Get current user from Redux store
  const isLiked = article?.likes.includes(currentUser?._id);
  const {data} = useGetUserQuery()
  const bookmark = data?.user?.bookmarks?.includes(article?._id)
  const { token } = useSelector((state) => state.auth);
  const handleCopy = () => {
    const url =
      window.location.origin + `/view-article/${article?._id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopy(true); // Update the copy state
        toast.success('!Copied');
        setTimeout(() => setCopy(false), 2000); // Reset copy state after 2 seconds
      })
      .catch((error) => {
        console.error('Error copying URL:', error);
      });
  };
  const handleLike = async () => {
    try {
      if (isLiked) {
        await unlikeArticle(article?._id);
      } else {
        await likeArticle(article?._id);
      }
    } catch (error) {
      console.error('Error handling like:', error);
    }
  };
  const handleBookmark = async () => {
    await bookmarkArticle(article?._id);
  };
  // useEffect(() => {
  //   if (isSuccess) {
  //     setBookmark(!bookmark);
  //   }
  // }, [isSuccess]);
  const truncateText = (text, limit) => {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  };
  return (
    <div className="flex md:flex-row hover:bg-gray-100 rounded-md p-1 flex-col-reverse gap-2 h-fit border-b pb-3 border-[#E4E4E7]  w-full">
      <div className="relative md:w-[250px] w-full h-[170px] md:h-[130px] cursor-pointer"
                onClick={() => router.push(`/view-article/${article?._id}`)}

      >
        <Image
          src={article?.coverImage || demoCoverImage}
          alt="profile"
          className="rounded-sm object-cover"
          fill
          sizes="(max-width: 768px) 100vw, 250px"
        />
      </div>
      <div className="flex flex-col w-full gap-1 p-2">
        <div className="flex gap-2 items-center">
          <span
             onClick={() =>
              router.push(`/@${article?.author?.username}`)
            }
            className="flex gap-2 cursor-pointer"
          >
            {article?.author?.profileImage ? (
              <Image
                src={article?.author?.profileImage}
                alt=""
                width={30}
                height={30}
                className="w-[30px] h-[30px] rounded-full bg-gray-600 text-white flex justify-center items-center"
              />
            ) : (
              <span className="w-[30px] h-[30px] rounded-full bg-gray-600 text-white flex justify-center items-center">
                {article?.author?.name?.charAt(0) || 'A'}
              </span>
            )}
            <p className="text-[16px] text-[#8641BD] font-[500]">
              {article?.author?.name || 'Author'}{' '}
            </p>
          </span>
          {/* <span
            onClick={() => router.push('/dashboard/newsletter')}
            className="text-[13px] hover:text-[#8641BD] cursor-pointer font-[700]"
          >
            في نشرة رسالة السبت
          </span> */}
        </div>
        {/* title */}
        <div
          onClick={() => router.push(`/view-article/${article?._id}`)}
          className=" cursor-pointer w-full"
        >
          <p className="text-[#18171D] text-[18px] xl:text-[20px] font-[600] ">
            {truncateText(article?.title, 12)}
          </p>
        </div>
        {/* content */}
        <p className="text-[#27262B] xl:text-[14px] text-[12px] font-[500]">
          {truncateText(article?.subTitle, 20)}
        </p>
        {/*  */}
        <div className="flex relative items-center w-full justify-between">
          <span className="flex gap-3">
            <p className="text-[13px] font-[500]">
              {formatDate(article?.createdAt)}
            </p>
            {
              token &&  <span className="flex gap-2 text-[#4D5861] text-[11px] font-[500]">
              <span
                onClick={() => setIsOpen(!isOpen)}
                className="flex gap-1 hover:text-black cursor-pointer"
              >
                <p>
                  {article?.comments?.length > 0
                    ? article?.comments?.length
                    : ''}
                </p>
                <MessageCircleMore size={20} />
              </span>
              <span
                className="flex gap-1 cursor-pointer hover:text-black"
                onClick={handleLike}
              >
                <p>{article.likes.length}</p>
                {isLiked ? (
                  <AiFillLike className={`text-black`} size={20} />
                ) : (
                  <AiOutlineLike size={20} />
                )}
              </span>
            </span>
            }
           
          </span>
          <span className="flex text-[#4D5861] gap-2">
            {token && (bookmark ? (
              <HiMiniBookmark
                onClick={handleBookmark}
                className={`cursor-pointer hover:text-black`}
                size={20}
              />
            ) : (
              <Bookmark
                onClick={handleBookmark}
                className={`cursor-pointer hover:text-black`}
                size={20}
              />
            ))}
            <CopyToClipboard onCopy={handleCopy}>
              <FiCopy
                className={`cursor-pointer hover:text-black ${copy ? 'text-black' : ''}`}
                size={20}
              />
            </CopyToClipboard>
            {/* <Ellipsis onClick={()=>setReport(!report)} className="cursor-pointer hover:text-black"  size={20} /> */}
          </span>
          {/* {report && <span className='bg-white rounded-md text-black flex items-center gap-1 border p-2 absolute bottom-[-45px] w-fit -left-2 cursor-pointer'>
                <Flag width={15} height={15} className='cursor-pointer'/> 
                الإبلاغ عن المنشور</span>} */}
        </div>
      </div>
      {isOpen && (
        <CommentModal
          isOpen={isOpen}
          articleId={article?._id}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ExploreArticleCard;
